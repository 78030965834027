<template>
  <a-card class="card" :bordered="false">
    <base-page
      @modelShow="handleModelShow"
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getCmsMetaPage"
      :deleteFun="deleteCmsMetaInfo"
      :createFun="createCmsMetaInfo"
      :updateFun="updateCmsMetaInfo"></base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getFirstLetter } from '@/utils/util'
import { getCmsMetaPage, deleteCmsMetaInfo, createCmsMetaInfo, updateCmsMetaInfo } from '@/api/cms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        {
          label: '简码', name: 'short_code', fieldType: '', queryType: '%'
        }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '运输单位简称',
          dataIndex: 'short_name'
        },
        {
          title: '简码',
          dataIndex: 'short_code'
        },
        {
          title: '联系人',
          dataIndex: 'contact_name'
        },
        {
          title: '联系电话',
          dataIndex: 'contact_phone'
        },
        {
          title: '联系地址',
          dataIndex: 'contact_address'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入运输单位全称', trigger: 'blur' }
        ]
      },
      modelTitle: '运输单位',
      formFields: [
        {
          label: '运输单位简称', name: 'short_name', type: 'default', ref: 'name'
        },
        {
          label: '简码', name: 'short_code', type: 'default'
        },
        {
          label: '运输单位全称', name: 'name', type: 'default'
        },
        {
          label: '联系人', name: 'contact_name', type: 'default'
        },
        {
          label: '联系电话', name: 'contact_phone', type: 'default'
        },
        {
          label: '联系地址', name: 'contact_address', type: 'default'
        }
      ],
      moduleName: 'transport_company'
    }
  },
  methods: {
    getCmsMetaPage,
    deleteCmsMetaInfo,
    createCmsMetaInfo,
    updateCmsMetaInfo,
    handleModelShow(a) {
      const nameObj = a.$refs['name'][0].$refs.input
      nameObj.oninput = () => {
        a.originForm.short_code = getFirstLetter(a.originForm.short_name)
      }
    }
  }
}
</script>
